import React from 'react';
import path from 'path-browserify';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { graphql, PageProps, Link } from 'gatsby';
import { ChevronRight, Clock } from 'react-feather';
import classnames from 'classnames';
import get from 'lodash/get';

import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import Breadcrumb from '../../components/Breadcrumb';
import SEO from '../../components/SEO/SEO';
import HeroPost from '../../components/HeroPost';
import { StrapiInlineCtaComponent } from '../../components/StrapiComponents/StrapiInlineCtaComponent';
import { RichTextField } from '../../components/RichTextField/RichTextField';
import { SinglePromotionPageQuery, InlineCtaComponentFragment, Scalars, Maybe } from '../../../graphql.schema';
import { ModalRawHTML } from '../../components/RawHTML/ModalRawHTML';
import { GlobalPageContext } from '../../types';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './promotions-page.module.scss';
import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';

type PageContextType = GlobalPageContext & { id: Scalars['ID'] };

const PromotionsPage: React.FC<PageProps<SinglePromotionPageQuery, PageContextType>> = ({ data, pageContext }) => {
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3.id, ...websiteLocaleV3.attributes });
  // `identifier` exists in the model but v3 grapql schema seems to have skipped it
  const promotion = data.strapi.promotion as SinglePromotionPageQuery['strapi']['promotion'] & { identifier?: string };

  if (!websiteLocale || !promotion) return <p>Not found</p>;

  const desc = promotion.seo?.metaDescription ?? promotion.pageSettings?.metaDescription ?? promotion.identifier ?? '';

  const checkContentExist = (htmlInput?: Maybe<string>) => {
    const regex = /\s|<[^>]*>?/gm;
    return htmlInput && (htmlInput.replace(regex, '').length > 0 || /<img/.test(htmlInput));
  };
  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={promotion.seo?.metaTitle ?? promotion.pageSettings?.title ?? promotion.title ?? ''}
        description={desc}
        image={promotion.seo?.metaImage.url ?? promotion.image?.url}
        avoidIndexing={promotion.pageSettings?.avoidIndexing ?? false}
        canonicalURL={promotion.seo?.canonicalURL}
        keywords={promotion.seo?.keywords}
        metaSocial={promotion.seo?.metaSocial}
        structuredData={promotion.seo?.structuredData}
      />
      <div className="container">
        <Breadcrumb pageContext={pageContext} lastCrumb={promotion.title}></Breadcrumb>
        <HeroPost variant="default" title={promotion.title} imageSrc={promotion.image?.url} />
      </div>
      <div className={classnames('text-2 black-2', styles.date)}>
        <time className="is-flex mb-2">
          <Clock className="text-2 black-4 mr-3" />
          Expires:
          <FormattedDate
            value={promotion.expiresAt}
            weekday="long"
            month="long"
            day="2-digit"
            hour="2-digit"
            minute="2-digit"
          />
        </time>
      </div>
      <div className={styles.main}>
        <RichTextField className="pt-10 pb-5 black-2" content={promotion.body} />
        {checkContentExist(promotion.termsAndConditions) ? (
          <Link
            to={path.join(
              pageContext.breadcrumb?.location ?? '/',
              pageContext.originalPathPrefixes.terms_and_conditions,
            )}
            className="is-flex is-align-items-center has-text-weight-semibold mt-0 mb-3"
          >
            <FormattedMessage id="termsAndConditionsLinkText" />
            <ChevronRight size={16} className={styles.chevron} />
          </Link>
        ) : null}
      </div>

      {promotion.callToAction && (
        <StrapiInlineCtaComponent {...(promotion.callToAction as InlineCtaComponentFragment)} />
      )}
      <div className={styles.form}>
        <ModalRawHTML className="container" html={promotion.script ?? ''} />
      </div>
      {get(websiteLocale, 'footerCta', null) && <StrapiFooterCtaComponent {...get(websiteLocale, 'footerCta', null)} />}
    </LayoutWrapper>
  );
};

export const query = graphql`
query SinglePromotionPage($id: ID!) {
  strapi {
    promotion(id: $id) {
      data {
        id
        attributes {
          title
          slug
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
          expiresAt: expires_at
          image {
            ...StrapiImageEntityResponse
          }
          body
          termsAndConditions: terms_and_conditions
          callToAction: call_to_action {
            ...InlineCTAComponent
          }
          pageSettings: page_settings {
            ...PageSettings
          }
          seo {
            ...SEOComponent
          }
          identifier
          script
        }
      }
    }
  }
}
`;

export default intlWrapperHOC(v4tov3HOC(PromotionsPage));
